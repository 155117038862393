import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "La rétroaction immédiate",
      description: "Labtice Pro permet aux enseignants de fournir une rétroaction immédiate aux apprenants  en rentrant en communication en temps réel avec eux.  L’écoute discrète ou les enregistrements audio peuvent être écoutés instantanément par l'enseignant, qui peut ensuite fournir des commentaires précis sur la prononciation, la grammaire, le vocabulaire, etc. Cela favorise une amélioration rapide des compétences linguistiques."
    },
    {
      heading: "La flexibilité et l’autonomie",
      description: "Labtice Pro offre la possibilité d'un apprentissage individualisé et autonome. Les apprenants peuvent travailler à leur propre rythme, choisir les activités qui correspondent à leurs besoins et s'entraîner sur des compétences spécifiques. Cela permet de répondre aux besoins variés des apprenants et de favoriser leur autonomie."
    },
    {
      heading: "L’utilisation de ressources multimédias ",
      description: "Avec Labtice Pro,  l’enseignant va pouvoir utiliser tous types de document  multimédias tel que des vidéos, des enregistrements audio, des logiciels interactifs, des supports de documents écrits, etc. Ces ressources fournissent des supports visuels et auditifs qui facilitent la compréhension et l'apprentissage de la langue. Avec Labtice Pro, la diffusion de documents pourra se faire très simplement  en temps réel sur les postes des apprenants ou groupes d’apprenants pour une pédagogie différenciée."
    },
    {
      heading:"Une expérience immersive",
      description: "Labtice Pro permet de recréer des situations authentiques, telles que des dialogues de la vie quotidienne, des situations professionnelles, des interactions culturelles, etc. Cela permet aux apprenants de se familiariser avec la langue dans un contexte réaliste, ce qui renforce leur capacité à utiliser la langue dans des situations réelles."
    }
    
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+4).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
