import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import '@blueprintjs/core/lib/css/blueprint.css';
import { Tree, Classes, Intent } from "@blueprintjs/core";
import { Button,Switch, Card, Elevation,Icon, Overlay,H4 } from "@blueprintjs/core";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import MainFeatureBlog from "components/features/MainFeatureBlog.js";

import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/SimpleTestimonials";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import FeatureWithStepsSuite from "components/features/TwoColWithStepssuite.js";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";

import { ReactComponent as HeadphonesIcon } from "feather-icons/dist/icons/headphones.svg";
import { ReactComponent as MousePointerIcon } from "feather-icons/dist/icons/mouse-pointer.svg";


const HighlightedText = tw.span`text-primary-500`

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  
  const [blogPost, setBlogPost] = React.useState(0);
  const [afficheblog, setAfficheblog] = React.useState(0);
  
  const [testimonials, setTestimonials] = React.useState( [
    {
      id:1,
      title:'Les fonctionnalités de LABTICE PRO pour l\'enseignant',
      imageSrc:
      "../../images/frames/1.html",
      quote:
        "",
        description:"Le suivi individualisé des apprenants",
        details:"Suivre, surveiller, aider vos apprenants en les écoutant discrètement, en parlant avec eux, ou en les aidant directement sur leurs ordinateurs sans vous déplacer. Si vos apprenants on besoin de vous, ils pourront vous envoyer un message écrit instantanément et vous serez notifés de la réception de celui-ci.",
        visible:false,
      customerName: "",
      customerTitle: ""
    },
    {
      id:2,
      imageSrc:
      "../../images/frames/2.html",
      quote:
        "",
        title:'',
        description:"Diffuser, distribuer et récupérer tous type de documents",
        details:'Vous souhaitez distribuer un document et qu\'il s\'ouvre automatiquement sur les postes des apprenants. Vous voulez envoyer des consignes ou des liens Internets à vos apprenants, récupérer leurs travaux, travailler en groupe différencié ... ',
        visible:false,
      customerName: "",
      customerTitle: ""
    },
    {
      id:3,
      title:'',
      imageSrc:
      "../../images/frames/3.html",
      quote:
        "",
        description:"Autoriser - Interdire",
        details:'Vous voulez interdire la navigation Internet le temps que vous voulez, ou encore imposer des sites Internet à vos apprenants, capter l\'attention des apprenants en bloquant leur écran, clavier et souris, bloquer certaines applications, couper le son des ordinateurs momentanément, bloquer des clefs usb, des smartphones branchés en usb ...',
        visible:false,
      customerName: "",
      customerTitle: ""
    }
    ,
    {
      id:4,
      title:'',
      imageSrc:
      "../../images/frames/4.html",
      quote:
        "",
        description:"Mettre en relation collaborative les apprenants",
        details:'Très facilement mettez en communication audiophonique les apprenants avec la possibilité de récupérer leur enregistrement, ou vous avez aussi la possibilité de les mettre dans les salles de discussion écrites.',
        visible:false,
      customerName: "",
      customerTitle: ""
    }
    ,
    {
      id:5,
      title:'',
      imageSrc:
      "../../images/frames/5.html",
      quote:
        "",
        description:"Laboratoire de langues",
        details:'Retrouvez toutes les fonctions des laboratoires de langues...',
        visible:false,
      customerName: "",
      customerTitle: ""
    }
  ]);
  const handleClickPost=(id)=>
  {
    //alert(id);
    setBlogPost(id);
    setAfficheblog(id);
  }
  const handleGrapheVisible=(id)=>
  {
    var obj=[];
    testimonials.map((it)=>{if (it.id==id)it.visible=!it.visible; obj.push(it)});
    setTestimonials(obj);
  }
  const configMainFeature={
  id:1,
  description : "En utilisant un laboratoire de langues en classe, les apprenants peuvent bénéficier d'une approche pédagogique plus interactive, centrée sur la pratique orale, la rétroaction instantanée et l'autonomie.\n Ces avantages contribuent à améliorer la motivation, l'engagement et les résultats d'apprentissage des étudiants dans l'étude des langues.\n",
  primaryButtonText : "",
  primaryButtonUrl :"",
  imageSrc : "../../images/image1.jpg",
  buttonRounded : true,
  imageRounded : true,
  imageBorder : false,
  imageShadow : false,
  showDecoratorBlob : false,
  textOnLeft : true,
  features : null,
  iconRoundedFull : true,
  iconFilled : true,
  iconContainerCss : null,
  heading:"Pourquoi utiliser un laboratoire de langues",
  subheading:'LABTICE 2023 - LABORATOIRE DE LANGUES',
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  features : [
    {
      Icon: HeadphonesIcon,
      title: "Labtice Pro est un véritable laboratoire de langues moderne.",
      description: "Retrouvez toutes les fonctionnalités d'un laboratoire de langues ainsi que les fonctionnalités d'un logiciel d'animations pédagogiques.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    }
  ],
  };
  const configBlogs=[{
    id:1,
    description : "Une fois Labtice enseignant lancé, la fenêtre vous propose de choisir une de vos salles.\n Cliquez sur votre salle.",
  primaryButtonText : "",
  primaryButtonUrl :"",
  imageSrc : "../../images/blog/article1/1.jpg",
  imageSrc2: "../../images/blog/article1/2.jpg",
  buttonRounded : true,
  imageRounded : true,
  imageBorder : false,
  imageShadow : false,
  showDecoratorBlob : false,
  textOnLeft : true,
  features : null,
  iconRoundedFull : true,
  iconFilled : true,
  iconContainerCss : null,
  heading:"Labtice est très simple d'utilisation",
  subheading:'Le démarrage de LABTICE',
  
  features : [
    {
      Icon: MousePointerIcon,
      title: "En cliquant sur votre salle, l'interface principale va s'ouvrir",
      description: "Une fois l'interface principale ouverte, vous visualisez tous les postes de vos apprenants",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    }
  ],
  },
  {
    id:2,
    description : "",
  primaryButtonText : "",
  primaryButtonUrl :"",
  imageSrc : " ../images/blog/article2/cover2.jpg",
  imageSrc2: "",
  video:"",//"https://www.youtube.com/embed/1ZZuA-DEdps",
  video_title:"Démonstration de LABTICE nouveau laboratoire de langues",
  buttonRounded : true,
  imageRounded : true,
  imageBorder : false,
  imageShadow : false,
  showDecoratorBlob : false,
  textOnLeft : true,
  features : null,
  iconRoundedFull : true,
  iconFilled : true,
  iconContainerCss : null,
  heading:"Découverte de l'interface enseignante LABTICE",
  subheading:"Démarrer",
  
  features : [
    {
      Icon: MousePointerIcon,
      title: "Voir l'écran d'un apprenant, l'aider , l'écouter discrètement ou dialoguer avec lui",
      description: "Bientôt une présentation en vidéo",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    }
  ],
  }];
  return (
    <div>
      <AnimationRevealPage><Hero /></AnimationRevealPage>
      
      {/*<FeatureStats/>
      <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      />
      heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
      <MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
      />*/}
       <AnimationRevealPage><MainFeature
        config={configMainFeature}
        imageSrc={macHeroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /></AnimationRevealPage>
      <AnimationRevealPage><FeatureWithSteps
        subheading={<Subheading>Que permet LABTICE PRO</Subheading>}
        
        textOnLeft={false}
        imageSrc="../../images/lv1r.png"
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /></AnimationRevealPage>
      <AnimationRevealPage><FeatureWithStepsSuite
        subheading={<Subheading>Que permet LABTICE PRO</Subheading>}
        
        textOnLeft={true}
        imageSrc="../../images/interface_enseignant.jpg"
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /></AnimationRevealPage>
      {testimonials.map((it,index)=>
      <AnimationRevealPage style={{minHeight:100}}>
      <Testimonial handleGrapheVisible={handleGrapheVisible} style={{minHeight:100}} key={"t"+index} testimonial={it} index={index}/></AnimationRevealPage>)}
      {/*<Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />*/}
     
      <Blog
        handleClickPost={handleClickPost}
        subheading="Blog"
        heading={<>Cas d'utilisations de <HighlightedText>LABTICE PRO</HighlightedText></>}
      />
      
      <GetStarted/>
      <Footer />
      <Overlay isOpen={afficheblog>0}>
        <Card style={{width: '90%',overflow: 'auto',margin: '5% 5%',height:'90%'}}>
          <Button style={{zIndex:100, position:'fixed',pointer:'cursor'}} icon="cross" intent={Intent.DANGER} on onClick={(ev)=>setAfficheblog(0)}></Button>
        {configBlogs.map((configMainFeature)=>blogPost==configMainFeature.id?<div><MainFeatureBlog
        config={configMainFeature}
        imageBorder={true}
        imageDecoratorBlob={true}
      /></div>:null)}
      </Card>
      </Overlay>
    </div>
  );
}
