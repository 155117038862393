import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`absolute`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-row max-w-xs lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default class MainFeatureBlog extends React.Component
{
  constructor(props) {
    super(props);
  }

  
   
  ;
  render(){
  return (
    <Container style={{padding:40}}>
      <TwoColumn>
        <ImageColumn>
          {this.props.config.video!=""?
          <iframe width="100%" height="100%" src={this.props.config.video} title={this.props.config.video_title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          :null}
          <Image style={{position:'relative',top:0}}  src={this.props.config.imageSrc} imageBorder={this.props.config.imageBorder} imageShadow={this.props.config.imageShadow} imageRounded={this.props.config.imageRounded} />
          
          {this.props.config.showDecoratorBlob && <DecoratorBlob />}
          {this.props.config.imageSrc2!=""?<Image style={{marginTop:40}} src={this.props.config.imageSrc2} imageBorder={this.props.config.imageBorder} imageShadow={this.props.config.imageShadow} imageRounded={this.props.config.imageRounded} />:null}
          
        </ImageColumn>
        <TextColumn textOnLeft={this.props.config.textOnLeft}>
          <TextContent>
            <Subheading>{this.props.config.subheading}</Subheading>
            <Heading>{this.props.config.heading}</Heading>
            <Description>{this.props.config.description}</Description>
            <Features>
              {this.props.config.features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer>
                    <FeatureIconContainer
                      iconFilled={this.props.config.iconFilled}
                      iconRoundedFull={this.props.config.iconRoundedFull}
                      css={feature.iconContainerCss || this.props.config.iconContainerCss}
                    >
                      {<feature.Icon />}
                    </FeatureIconContainer>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>

            {this.props.config.primaryButtonText!=""? <PrimaryButton buttonRounded={this.props.config.buttonRounded} as="a" href={this.props.config.primaryButtonUrl}>
              {this.props.config.primaryButtonText}
            </PrimaryButton>:null}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
  }
}
